
/* stuff im adding */

.company-name{
  font-size: 10px;
  font-weight: 300;
  text-align: left;
  color: rgba(0, 0, 0, 0.38);
}

.demo-title{
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 400;
  text-align: left;
}

.demo-hr{
  background-color: rgb(231, 231, 231);
  margin-bottom: 20px;
  width: 100%;
  height: 1px;
}

.changed-info{
  font-size: 10px;
  margin-bottom: 8px;
  text-align: left;
  color: black;
}

/* Normal Stuff */
.md-iphone-5 .md-body {
  width: 100%;
  height: 100%;
  border-radius: 3.75em;
  border-style: solid;
  border-width: 0.375em;
  position: relative;
}

.md-iphone-5 .md-screen {
  position: relative;
  margin: 0 auto;
  background-color: #161616;
  border-radius: 0.25em;
  overflow: hidden;
  box-shadow: 0 0 0px 3px #161616;
}
.md-iphone-5 .md-screen img {
  width: 100%;
}

.md-iphone-5 .md-home-button {
  display: block;
  width: 3.625em;
  height: 3.625em;
  margin: 0 auto;
  position: relative;
  border-radius: 100%;
  border: none;
  cursor: default;
  font-size: 100%;
}
.md-iphone-5 .md-home-button:after {
  content: "";
  display: block;
  width: 1.125em;
  height: 1.125em;
  margin: 0 auto;
  position: relative;
  border-style: solid;
  border-width: 0.125em;
  border-radius: 0.25em;
}

.md-iphone-5 .md-front-camera {
  width: 9.875em;
  height: 2.275em;
  margin: 0 auto;
  position: relative;
  border-radius: 0 0 10px 10px;
  z-index: 1000;
}
.md-iphone-5 .md-front-camera:after {
    width: .1875em;
    height: 1.875em;
    position: absolute;
    right: -.5em;
    top: 5.9375em;
    border-radius: .125em 0 0 .125em;
}

.md-iphone-5.md-glare:before {
  content: "";
  display: block;
  width: 50%;
  height: 83%;
  position: absolute;
  top: 0.625em;
  right: 0.625em;
  border-radius: 0 3.125em 0 0;
  z-index: 1;
}

.md-iphone-5:after {
  content: "";
    width: .1875em;
    height: 4.875em;
    position: absolute;
    right: -.2em;
    top: 5.9375em;
    border-radius: .125em 0 0 .125em;
}

.md-iphone-5 {
  width: 22.8125em;
  height: 49em;
  position: relative;
}
.md-iphone-5 .md-front-camera {
  top: 0;
}
.md-iphone-5 .md-top-speaker {
  width: 3.75em;
  height: 0.875em;
  margin: 0 auto;
  position: relative;
  top: 2.25em;
  border-radius: 3.125em;
}
.md-iphone-5 .md-top-speaker:after {
  content: "";
  display: block;
  width: 3.25em;
  height: 0.375em;
  margin: 0 auto;
  position: relative;
  top: 0.25em;
  background-color: #4b414a;
  border-radius: 3.125em;
}
.md-iphone-5 .md-screen {
  width: 20em;
  height: 46.5em;
  top: -1.5em;
  border-radius: 30px;

}
.md-iphone-5 .md-home-button {
  bottom: -5.9375em;
}
.md-iphone-5 .md-buttons {
  width: 0.1875em;
  height: 2.875em;
  position: absolute;
  left: -0.5em;
  top: 5.9375em;
  border-radius: 0.125em 0 0 0.125em;
}
.md-iphone-5 .md-buttons:after, .md-iphone-5 .md-buttons:before {
  content: "";
  display: block;
  position: absolute;
  width: 0.1875em;
  height: 3.5625em;
  border-radius: 0.125em 0 0 0.125em;
}
.md-iphone-5 .md-buttons:after {
  top: 4.125em;
}
.md-iphone-5 .md-buttons:before {
  top: 8.25em;
}
.md-iphone-5.md-glare:before {
  background: -webkit-linear-gradient(16deg, rgba(255, 255, 255, 0) 50%, rgba(247, 248, 240, 0.025) 50%, rgba(250, 245, 252, 0.08));
  background: -moz-linear-gradient(16deg, rgba(255, 255, 255, 0) 50%, rgba(247, 248, 240, 0.025) 50%, rgba(250, 245, 252, 0.08));
  background: -o-linear-gradient(16deg, rgba(255, 255, 255, 0) 50%, rgba(247, 248, 240, 0.025) 50%, rgba(250, 245, 252, 0.08));
  background: linear-gradient(74deg, rgba(255, 255, 255, 0) 50%, rgba(247, 248, 240, 0.025) 50%, rgba(250, 245, 252, 0.08));
}
.md-iphone-5.md-landscape {
  -webkit-transform: rotateZ(90deg);
  -moz-transform: rotateZ(90deg);
  -ms-transform: rotateZ(90deg);
  -o-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}
.md-iphone-5.md-landscape.md-glare:before {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  left: 0.625em;
  top: 0.625em;
}

.md-white-device .md-front-camera, .md-white-device .md-top-speaker, .md-white-device .md-home-button {
  background: -webkit-linear-gradient(#d0d0c5, #eeeeea);
  background: -moz-linear-gradient(#d0d0c5, #eeeeea);
  background: -o-linear-gradient(#d0d0c5, #eeeeea);
  background: linear-gradient(#d0d0c5, #eeeeea);
}
.md-black-device .md-front-camera, .md-black-device .md-top-speaker, .md-black-device .md-home-button {
  background: -webkit-linear-gradient(#141917, #202623);
  background: -moz-linear-gradient(#141917, #202623);
  background: -o-linear-gradient(#141917, #202623);
  background: linear-gradient(#141917, #202623);
}
.md-white-device.md-landscape .md-home-button, .md-white-device.md-landscape .md-front-camera, .md-white-device.md-landscape .md-top-speaker {
  background: -webkit-linear-gradient(left, #d0d0c5, #eeeeea);
  background: -moz-linear-gradient(left, #d0d0c5, #eeeeea);
  background: -o-linear-gradient(left, #d0d0c5, #eeeeea);
  background: linear-gradient(to right, #d0d0c5, #eeeeea);
}
.md-black-device.md-landscape .md-home-button, .md-black-device.md-landscape .md-front-camera, .md-black-device.md-landscape .md-top-speaker {
  background: -webkit-linear-gradient(left, #141917, #202623);
  background: -moz-linear-gradient(left, #141917, #202623);
  background: -o-linear-gradient(left, #141917, #202623);
  background: linear-gradient(to right, #141917, #202623);
}
.md-white-device .md-body {
  background-color: #ebebe4;
  border-color: #f1f2eb;
}
.md-white-device .md-buttons {
  background-color: #ebebe4;
}
.md-white-device .md-buttons:after, .md-white-device .md-buttons:before {
  background-color: #ebebe4;
}
.md-white-device .md-front-camera:after {
  background-color: #8c8091;
}
.md-white-device .md-top-speaker:after {
  background-color: #4b414a;
}
.md-white-device .md-home-button:after {
  border-color: #eff1e6;
}
.md-white-device:after {
  background: #ebebe4;
}
.md-black-device .md-body {
  background-color: #1b211e;
  border-color: #808080
}
.md-black-device .md-buttons {
  background-color: #808080
}
.md-black-device .md-buttons:after, .md-black-device .md-buttons:before {
  background-color: #808080
}
.md-black-device .md-front-camera:after {
  background-color: #2c292f;
}
.md-black-device .md-top-speaker:after {
  background-color: #191616;
}
.md-black-device .md-home-button:after {
  border-color: #808080
}
.md-black-device:after {
  background: #808080
}


ion-content-section {
  position: relative;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  height: 100%;
}
ion-content {
  position: relative;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  height: 100%;
}
ion-content.js-scroll > scroll-content {
  position: relative;
  min-height: 100%;
  overflow-x: initial;
  overflow-y: initial;
  -webkit-overflow-scrolling: auto;
  will-change: initial;
}
ion-content {
  color: #000;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
ion-card {
  display: block;
  overflow: hidden;
}
ion-card img {
  display: block;
  width: 100%;
}
ion-card-header {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
ion-card-content {
  display: block;
}
ion-card {
  height: auto;
  width: 100%;
  border-radius: 2px;
  background: #fff;
  margin-top: -128px;
}
ion-card ion-list {
  margin-bottom: 0;
}
ion-card ion-list .item {
  padding-right: 0;
}
ion-card ion-list ion-label {
  padding: 0;
}
ion-card > .item:last-child,
ion-card > .item-wrapper:last-child .item {
  border-bottom: 0;
}
ion-card .item-inner {
  border: 0;
}
ion-card ion-card-content {
  padding: 0px 6px;
  font-size: 1.4rem;
  line-height: 1.4;
}
ion-card ion-card-header {
  padding: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #333;
}
ion-card ion-card-header + ion-card-content,
ion-card .item + ion-card-content {
  padding-top: 0;
}
ion-card ion-note[item-left],
ion-card ion-note[item-right] {
  font-size: 1.3rem;
}
ion-card ion-card-title {
  display: block;
  margin: 2px 0 2px;
  padding: 8px 0 8px 0;
  font-size: 1.8rem;
  line-height: 1.2;
  color: #222;
}
ion-card h1 {
  margin: 0 0 2px;
  font-size: 2.4rem;
  font-weight: normal;
}
ion-card h2 {
  margin: 2px 0;
  font-size: 1.6rem;
  font-weight: normal;
}
ion-card h3,
ion-card h4,
ion-card h5,
ion-card h6 {
  margin: 2px 0;
  font-size: 1.4rem;
  font-weight: normal;
}
ion-card p {
  margin: 0 0 2px;
  font-size: 1.4rem;
  color: #666;
}
ion-card + ion-card {
  margin-top: 0;
}
.company-logo-demo {
  height: 40px;
  width: 40px;
}

.demo-hr {
  margin: 10px 0px;
}
.ion-item-demo {
  /* border-top: 1px solid #EEEEEE; */
  padding: 4px 0;
  font-size: 10px;
}
.button-dark {
  color: #fff;
  background-color: #222;
}
.button-dark.activated {
  background-color: #343434;
}
.success-img {
  width: auto !important;
  display: block;
  margin: 0 auto;
}
.back-arrow-demo {
  font-size: 20px;
}
.logo-col-question {
    padding-left: 10px !important;
    padding-right: 0px !important;
}
.question-demo {
  font-size: 14px;
}
.demo-h5 {
  margin-bottom: 0 !important;
  font-size: 17px;
}
.right-padding-5 {
  margin-right: 5px;
}
.logo-col {
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.logo-col-question {
    padding-left: 10px !important;
    padding-right: 0px !important;
}